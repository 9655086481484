import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import * as PropTypes from 'prop-types'

import { Image } from '_atoms'
import { GenericContentSection } from '_molecules'
import variables from '_config/css-variables'

import styles from './styles.module.css'

export const LetsChatAbout = ({ image }) => {
  const intl = useIntl()
  return (
    <>
      <GenericContentSection
        sectionId="section4"
        title={intl.formatMessage({ id: 'blockchainServices.section4.title' })}
        titleColor="white"
        text={intl.formatMessage({ id: 'blockchainServices.section4.text' })}
        textClassname={styles.text}
        textColor="white"
        splitParagraphs
        buttonLabel={intl.formatMessage({ id: 'blockchainServices.section4.buttonText' })}
        buttonHref="#lets-chat"
        buttonClassname={styles.button}
        backgroundColor={variables['color-primary']}
        buttonType="outlineWhite"
      >
        <Image className={styles.image} fluid={image.fluid} alt="Section 4 image" />
      </GenericContentSection>
    </>
  )
}

LetsChatAbout.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.object,
  }).isRequired,
}
