import React from 'react'

import { BlueText, Button, Container, Heading, Text } from '_atoms'
import { CompanyCard } from '_molecules'
import bancoCentralLogo from '_images/blockchainServicePage/section5/banco-central-do-brasil.svg'
import hyperSpaceLogo from '_images/blockchainServicePage/section5/hyperspace.svg'
import tackomBankLogo from '_images/blockchainServicePage/section5/tackombank.svg'
import mercadobitcoinLogo from '_images/blockchainServicePage/section5/mercado-bitcoin.png'
import { useIntlNamespace } from '_hooks'

import styles from './styles.module.css'

export const HelpedCompanies = () => {
  const intl = useIntlNamespace('blockchainServices.section5')

  const cardSeeMoreText = intl(`cardsSeeMoreText`)

  const companies = [
    {
      text: intl('cards.tascombank.text'),
      link: intl('cards.tascombank.url'),
      linkText: cardSeeMoreText,
      logo: tackomBankLogo,
    },
    {
      text: intl('cards.bancocentraldobrasil.text'),
      link: intl('cards.bancocentraldobrasil.url'),
      linkText: cardSeeMoreText,
      logo: bancoCentralLogo,
    },
    {
      text: intl('cards.hyperspace.text'),
      link: intl('cards.hyperspace.url'),
      linkText: cardSeeMoreText,
      logo: hyperSpaceLogo,
    },
    {
      text: intl('cards.mercadobitcoin.text'),
      link: intl('cards.mercadobitcoin.url'),
      linkText: cardSeeMoreText,
      logo: mercadobitcoinLogo,
    },
  ]

  return (
    <div className={styles.wrapper}>
      <Container className={styles.wrapperContainer}>
        <Heading type="h2" bold className={styles.titleText}>
          {intl('title')}
          <BlueText squared>.</BlueText>
        </Heading>

        <div className={styles.grid}>
          {companies.map(company => (
            <CompanyCard
              text={company.text}
              link={company.link}
              linkText={company.linkText}
              logo={company.logo}
            />
          ))}
        </div>

        <div className={styles.wrapperButton}>
          <Button type="primary" href="/portfolio" className={styles.button}>
            <Text size={18} bold className={styles.buttonText}>
              {intl('buttonText')}
            </Text>
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default HelpedCompanies
