import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-simple-flex-grid'
import { useIntl } from 'gatsby-plugin-intl'

import { Container, Heading, Image, Text } from '_atoms'

import styles from './styles.module.css'

export const BlockchainServices = ({ icons }) => {
  const intl = useIntl()

  const cards = [
    {
      icon: icons[0].node.childImageSharp.fluid,
      title: intl.formatMessage({ id: 'blockchainServices.section8.cards.card1.title' }),
      description: intl.formatMessage({ id: 'blockchainServices.section8.cards.card1.text' }),
    },
    {
      icon: icons[1].node.childImageSharp.fluid,
      title: intl.formatMessage({ id: 'blockchainServices.section8.cards.card2.title' }),
      description: intl.formatMessage({ id: 'blockchainServices.section8.cards.card2.text' }),
    },
    {
      icon: icons[2].node.childImageSharp.fluid,
      title: intl.formatMessage({ id: 'blockchainServices.section8.cards.card3.title' }),
      description: intl.formatMessage({ id: 'blockchainServices.section8.cards.card3.text' }),
    },
    {
      icon: icons[3].node.childImageSharp.fluid,
      title: intl.formatMessage({ id: 'blockchainServices.section8.cards.card4.title' }),
      description: intl.formatMessage({ id: 'blockchainServices.section8.cards.card4.text' }),
    },
    {
      icon: icons[4].node.childImageSharp.fluid,
      title: intl.formatMessage({ id: 'blockchainServices.section8.cards.card5.title' }),
      description: intl.formatMessage({ id: 'blockchainServices.section8.cards.card5.text' }),
    },
    {
      icon: icons[5].node.childImageSharp.fluid,
      title: intl.formatMessage({ id: 'blockchainServices.section8.cards.card6.title' }),
      description: intl.formatMessage({ id: 'blockchainServices.section8.cards.card6.text' }),
    },
  ]
  return (
    <Container className={styles.wrapper}>
      <Row className={styles.titleHeader} justify="center" align="middle">
        <Heading type="h2" bold className={styles.titleText}>
          {intl.formatMessage({ id: 'blockchainServices.section8.title' })}
        </Heading>
      </Row>

      <div className={styles.grid}>
        {cards.map((card, index) => (
          <div index={index} className={styles.card}>
            <div className={styles.iconWrapper}>
              <Image fluid={card.icon} alt="" />
            </div>
            <Text size="16" className={styles.cardTitle}>
              {card.title}
            </Text>
            <Text size="16" className={styles.cardDescription}>
              {card.description}
            </Text>
          </div>
        ))}
      </div>
    </Container>
  )
}

BlockchainServices.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.node).isRequired,
}
export default BlockchainServices
