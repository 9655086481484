import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import PropTypes from 'prop-types'
import { Row } from 'react-simple-flex-grid'

import { Container, Heading, LastColoredCharText } from '_atoms'
import { TinySlider, BlogCard } from '_molecules'
import variables from '_config/css-variables'

import './styles.css'
import styles from './styles.module.css'

const section10 = 'blockchainServices.section10'

export const LearnMoreAbout = () => {
  const { formatMessage: intl } = useIntl()
  const blogCards = new Array(4).fill(null).map((_, index) => {
    const cardIndex = index + 1
    return {
      title: intl({ id: `${section10}.cards.card${cardIndex}.title` }),
      link: intl({ id: `${section10}.cards.card${cardIndex}.link` }),
      linkText: intl({ id: `${section10}.cards.card${cardIndex}.linkText` }),
      image: intl({ id: `${section10}.cards.card${cardIndex}.image` }),
    }
  })

  const sliderOptions = {
    mode: 'carousel',
    controls: false,
    mouseDrag: true,
    loop: false,
    autoplay: true,
    autoplayHoverPause: false,
    autoplayTimeout: 4000,
    autoplayText: ['', ''],
    items: 1,
    navPosition: 'bottom',
    rewind: true,
    responsive: {
      800: {
        items: 2,
        gutter: 32,
      },
      1200: {
        items: 3,
        gutter: 20,
      },
    },
  }
  return (
    <div id="blog-cards-container" className={styles.wrapper}>
      <Container className={styles.container}>
        <Row className={styles.titleHeader} justify="center" align="middle">
          <Heading type="h2" bold className={styles.titleText}>
            <LastColoredCharText
              content={intl({ id: `${section10}.title` })}
              color={variables['color-primary']}
            />
          </Heading>
        </Row>
        <TinySlider id="learn-more-about-slider" options={sliderOptions} className={styles.slider}>
          {blogCards.map((card, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`blogCard${index}`}>
              <BlogCard {...card} />
            </div>
          ))}
        </TinySlider>
      </Container>
    </div>
  )
}

LearnMoreAbout.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default LearnMoreAbout
