import React from 'react'

import styles from './styles.module.css'

export const BlockchainFooter = () => {
  const thisYear = new Date().getFullYear()

  return (
    <div className={styles.footer}>
      <p>© {thisYear} Cheesecake Labs, Inc.</p>
    </div>
  )
}
