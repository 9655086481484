import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Text } from '_components/atoms'

import styles from './styles.module.css'

export const ExpandableTextCard = ({ title, paragraph }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <button type="button" onClick={() => setExpanded(!expanded)} className={styles.card}>
      <div>
        <Text size={24} className={styles.title}>
          {title}
        </Text>

        {!expanded && (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.icon}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 1C12.9089 1 13.6458 1.70355 13.6458 2.57143V10.4286H22.3542C23.2632 10.4286 24 11.1321 24 12C24 12.8679 23.2632 13.5714 22.3542 13.5714H13.6458V21.4286C13.6458 22.2964 12.9089 23 12 23C11.0911 23 10.3542 22.2964 10.3542 21.4286V13.5714H1.64578C0.736842 13.5714 0 12.8679 0 12C0 11.1321 0.736842 10.4286 1.64578 10.4286H10.3542V2.57143C10.3542 1.70355 11.0911 1 12 1Z"
              fill="#BABABA"
            />
          </svg>
        )}
      </div>

      {expanded && (
        <Fade distance="25%" top>
          <div className={styles.paragraph}>{paragraph}</div>
        </Fade>
      )}
    </button>
  )
}

ExpandableTextCard.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
}
