import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { TwoColumnsCardGrid, GenericContentSection } from '_molecules'
import { Image, Text } from '_atoms'
import variables from '_config/css-variables'
import stellarLogo from '_images/blockchainServicePage/section6/stellar.svg'
import stellarIcon from '_images/blockchainServicePage/section6/stellar-icon.png'
import mobileApp from '_images/blockchainServicePage/section6/mobile-app.png'
import stablecoin from '_images/blockchainServicePage/section6/stablecoin.png'
import digitalAsset from '_images/blockchainServicePage/section6/digital-asset.png'
import { useIntlNamespace } from '_hooks'

import styles from './styles.module.css'

export const StellarIntegration = () => {
  const intl = useIntlNamespace('blockchainServices.section6')

  const stellarImage = (
    <div className={styles.stellarContainer}>
      <Image alt="" src={stellarLogo} className={styles.stellarLogo} />
    </div>
  )

  const cards = [
    {
      headline: intl('cards.card1.title'),
      iconSrc: stellarIcon,
    },
    {
      headline: intl('cards.card2.title'),
      iconSrc: mobileApp,
    },
    {
      headline: intl('cards.card3.title'),
      iconSrc: stablecoin,
    },
    {
      headline: intl('cards.card4.title'),
      text: intl('cards.card4.text'),
      iconSrc: digitalAsset,
      link: 'https://stellar.cheesecakelabs.com/',
    },
  ]

  const CardGrid = <TwoColumnsCardGrid cards={cards} />

  return (
    <GenericContentSection
      direction="rowReverse"
      sectionId="section6"
      title={intl('title')}
      titleClassname={styles.title}
      titleColor={variables['blockchain-white']}
      text={
        <FormattedMessage
          id="blockchainServices.section6.text"
          values={{
            p: (...chunks) => (
              <Text size="20" color="white" className={styles.text}>
                {chunks}
              </Text>
            ),
          }}
        />
      }
      textColor={variables['blockchain-white']}
      topContent={stellarImage}
      backgroundColor={variables['blockchain-blue']}
      splitParagraphs
      buttonLabel={intl('buttonText')}
      buttonHref="#lets-chat"
      buttonClassname={styles.button}
    >
      {CardGrid}
    </GenericContentSection>
  )
}

export default StellarIntegration
