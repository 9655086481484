import React from 'react'
import * as PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import variables from '_config/css-variables'
import { HeroSection } from '_organisms'
import { Image, Text } from '_atoms'

import styles from './styles.module.css'

export const BlockchainHero = ({ image }) => {
  const { formatMessage: intl } = useIntl()
  const section1Image = <Image className={styles.image} fluid={image.fluid} alt="hero image" />

  return (
    <HeroSection
      title={intl({ id: 'blockchainServices.section1.title' })}
      titleClassname={styles.title}
      text={
        <FormattedMessage
          id="blockchainServices.section1.text"
          values={{
            p: (...chunks) => (
              <Text size="22" color="white" className={styles.text}>
                {chunks}
              </Text>
            ),
            small: (...chunks) => (
              <Text color="white" size="12">
                {' '}
                {chunks}{' '}
              </Text>
            ),
          }}
        />
      }
      textClassname={styles.text}
      splitParagraphs
      image={section1Image}
      titleColor={variables['blockchain-white']}
      textColor={variables['blockchain-white']}
      backgroundColor={variables['blockchain-blue']}
      wrapperClassname={styles.heroWrapperClassname}
    />
  )
}

BlockchainHero.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.object,
  }).isRequired,
}
