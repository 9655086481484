import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import * as PropTypes from 'prop-types'

import variables from '_config/css-variables'
import { QuoteContainer } from '_molecules'
import { Button, Heading, Image, LastColoredCharText, Text } from '_atoms'
import clutchWhite from '_images/clutch-white.png'

import styles from './styles.module.css'

export const WhatsClientsSaying = ({ testimonialsImages }) => {
  const intl = useIntl()
  const renderClutchLogo = link => {
    return (
      <a href={link} target="_blank" rel="noreferrer" className="">
        <Image src={clutchWhite} alt="" />
      </a>
    )
  }

  const testimonials = [
    {
      id: 0,
      text: intl.formatMessage({
        id: 'blockchainServices.section7.testimonials.testimonial1.text',
      }),
      user: intl.formatMessage({
        id: 'blockchainServices.section7.testimonials.testimonial1.name',
      }),
      userImage: testimonialsImages.robImage,
      jobDescription: intl.formatMessage({
        id: 'blockchainServices.section7.testimonials.testimonial1.company',
      }),
      profileLink: '',
      badge: renderClutchLogo('https://clutch.co/profile/cheesecake-labs#review-2083231'),
    },
    {
      id: 1,
      text: intl.formatMessage({
        id: 'blockchainServices.section7.testimonials.testimonial2.text',
      }),
      user: intl.formatMessage({
        id: 'blockchainServices.section7.testimonials.testimonial2.name',
      }),
      userImage: testimonialsImages.karenImage,
      jobDescription: intl.formatMessage({
        id: 'blockchainServices.section7.testimonials.testimonial2.company',
      }),
      profileLink: '',
      badge: renderClutchLogo('https://clutch.co/profile/cheesecake-labs#review-2083231'),
    },
  ]

  return (
    <div className={styles.wrapper} id="section11">
      <div className={styles.titleContainer}>
        <Heading bold className={styles.titleText}>
          <LastColoredCharText
            content={intl.formatMessage({
              id: 'blockchainServices.section7.title',
            })}
            color={variables['color-primary']}
          />
        </Heading>
      </div>

      <QuoteContainer
        isWhite
        items={testimonials}
        textClassName={styles.quoteText}
        className={styles.quoteContainer}
        iconsClass={styles.quoteSlideDots}
        jobTextClassName={styles.quoteJobText}
        userPhotoClassName={styles.quoteUserPhoto}
        sliderClassName={styles.quoteSliderClassName}
        userDetailsClassName={styles.quoteUserDetails}
        sliderContainerClassName={styles.quoteSliderContainerClassName}
      />

      <div className={styles.buttonContainer}>
        <Button type="primary" href="/portfolio" className={styles.button}>
          <Text size={18} className={styles.buttonText}>
            {intl.formatMessage({
              id: 'blockchainServices.section7.buttonText',
            })}
          </Text>
        </Button>
      </div>
    </div>
  )
}

WhatsClientsSaying.propTypes = {
  testimonialsImages: PropTypes.object.isRequired,
}
