import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Fade from 'react-reveal/Fade'

import faqArrow from '_images/faq-arrow.png'

import styles from './styles.module.css'

export const FaqItem = ({ question, answer }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <button type="button" onClick={() => setOpen(!isOpen)} className={styles.faqItem}>
      <p className={styles.faqQuestion}>{question}</p>

      <img
        src={faqArrow}
        className={classNames([styles.faqArrow, { [styles.open]: isOpen }])}
        alt="FAQ Arrow Icon"
      />

      {isOpen && (
        <Fade distance="25%" top>
          <p className={styles.faqAnswer}>{answer}</p>
        </Fade>
      )}
    </button>
  )
}

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
}
