import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import * as PropTypes from 'prop-types'

import { GenericContentSection } from '_molecules'
import { useMobileView } from '_hooks'
import { Image } from '_atoms'
import variables from '_config/css-variables'

import styles from './styles.module.css'

export const TopNotchClutch = ({ image }) => {
  const intl = useIntl()
  const { isMobile } = useMobileView()

  return (
    <GenericContentSection
      direction={isMobile ? 'row' : 'row-reverse'}
      titleLastCharColor={variables['blockchain-blue']}
      title={intl.formatMessage({ id: 'blockchainServices.section11.title' })}
      titleColor={variables['blockchain-gray']}
      buttonLabel={intl.formatMessage({ id: 'blockchainServices.section11.buttonText' })}
      buttonHref="#lets-chat"
    >
      <div className={styles.wrapper}>
        <Image className={styles.image} fluid={image.fluid} alt="Section 11 alt" />
      </div>
    </GenericContentSection>
  )
}

TopNotchClutch.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.object,
  }).isRequired,
}
