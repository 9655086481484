import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import { Container, Heading, LastColoredCharText } from '_components/atoms'
import { FaqItem } from '_components/molecules/faq-item'

import styles from './styles.module.css'

const namespace = 'blockchainServices.section12'

export const FaqSection = () => {
  const { formatMessage: intl } = useIntl()
  const questions = new Array(3).fill(null)

  return (
    <div className={styles.wrapper}>
      <Container>
        <Heading bold type="h2" className={styles.title}>
          <LastColoredCharText content={intl({ id: `${namespace}.title` })} color="#0058FF" />
        </Heading>
        <Heading type="h4" className={styles.subtitle}>
          {intl({ id: `${namespace}.subtitle` })}
        </Heading>

        <div>
          {questions.map((_, idx) => (
            <FaqItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${namespace}-faq-${idx}`}
              question={intl({ id: `${namespace}.questions.question${idx + 1}.title` })}
              answer={intl({ id: `${namespace}.questions.question${idx + 1}.text` })}
            />
          ))}
        </div>
      </Container>
    </div>
  )
}
