import React from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import { BlueText, Container, Heading, Text } from '_components/atoms'
import { ExpandableTextCard } from '_components/molecules/expandable-text-card'

import styles from './styles.module.css'

const namespace = 'blockchainServices.section3'

export const BlockchainDevelopementServices = () => {
  const { formatMessage: intl } = useIntl()

  return (
    <section className={styles.sectionBackground}>
      <Container>
        <Heading className={styles.title} type="h2">
          {intl({ id: `${namespace}.title` })}
          <BlueText squared>.</BlueText>
        </Heading>

        <div className={styles.content}>
          <div className={styles.col}>
            <Text size={22}>{intl({ id: `${namespace}.text` })}</Text>
          </div>
        </div>

        <div className={styles.card}>
          <ExpandableTextCard
            title={intl({ id: `${namespace}.cards.card1.title` })}
            paragraph={
              <FormattedMessage
                id={`${namespace}.cards.card1.text`}
                values={{ p: (...chunks) => <Text size="20"> {chunks} </Text> }}
              />
            }
          />
        </div>
        <div className={styles.card}>
          <ExpandableTextCard
            title={intl({ id: `${namespace}.cards.card2.title` })}
            paragraph={
              <FormattedMessage
                id={`${namespace}.cards.card2.text`}
                values={{ p: (...chunks) => <Text size="20"> {chunks} </Text> }}
              />
            }
          />
        </div>
        <div className={styles.card}>
          <ExpandableTextCard
            title={intl({ id: `${namespace}.cards.card3.title` })}
            paragraph={
              <FormattedMessage
                id={`${namespace}.cards.card3.text`}
                values={{
                  p: (...chunks) => <Text size="20"> {chunks} </Text>,
                  strong: (...chunks) => (
                    <Text className={styles.strong} bold size="20">
                      {chunks}
                    </Text>
                  ),
                }}
              />
            }
          />
        </div>
        <div className={styles.card}>
          <ExpandableTextCard
            title={intl({ id: `${namespace}.cards.card4.title` })}
            paragraph={
              <FormattedMessage
                id={`${namespace}.cards.card4.text`}
                values={{ p: (...chunks) => <Text size="20"> {chunks} </Text> }}
              />
            }
          />
        </div>
      </Container>
    </section>
  )
}
