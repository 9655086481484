import React from 'react'
import { Row } from 'react-simple-flex-grid'

import { Button, Container, Heading, LastColoredCharText, Text } from '_atoms'
import { ProcessCard } from '_molecules'
import variables from '_config/css-variables'
import { useIntlNamespace } from '_hooks'

import styles from './styles.module.css'

export const BlockchainDevelopmentProcess = () => {
  const intl = useIntlNamespace('blockchainServices.section9')

  const cards = [
    {
      title: intl('cards.card1.title'),
      text: intl('cards.card1.text'),
      number: intl('cards.card1.number'),
    },
    {
      title: intl('cards.card2.title'),
      text: intl('cards.card2.text'),
      number: intl('cards.card2.number'),
    },
    {
      title: intl('cards.card3.title'),
      text: intl('cards.card3.text'),
      number: intl('cards.card3.number'),
    },
  ]

  return (
    <div className={styles.wrapperContainer}>
      <Container>
        <Row className={styles.wrapperTitle} justify="center" align="middle">
          <Heading type="h2" bold className={styles.titleText}>
            <LastColoredCharText content={intl('title')} color={variables['color-green-lighter']} />
          </Heading>
        </Row>
        <div className={styles.wrapperCards}>
          {cards.map(card => (
            <div className={styles.wrapperCard}>
              <ProcessCard
                number={card.number}
                title={card.title}
                text={card.text}
                rowWidthBreakingPoint={1300}
              />
            </div>
          ))}
        </div>
        <div className={styles.wrapperButton}>
          <Button type="primary" isExternalLink href="#lets-chat" className={styles.button}>
            <Text bold className={styles.buttonText}>
              {intl('buttonText')}
            </Text>
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default BlockchainDevelopmentProcess
