import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useIntl } from 'gatsby-plugin-intl'

import { NavbarOnly } from '_templates'
import {
  LetsChatAbout,
  BlockchainHero,
  WhatsClientsSaying,
  TopNotchClutch,
  StellarIntegration,
  BlockchainServices,
  BlockchainDevelopementServices,
  HelpedCompanies,
  LearnMoreAbout,
  BlockchainDevelopmentProcess,
  FaqSection,
  BlockchainFooter,
} from '_organisms/blockchain-service-page'
import { LestChatSection, SEO } from '_components/organisms'

import './styles.css'

const BLOCKCHAIN_CONTACT_FORM_ID = '41e8af25-8924-4743-b656-a26ebaea42c4'

const BlockChainPage = ({ data }) => {
  const { formatMessage: intl } = useIntl()

  const blockchainInterests = [
    {
      value: '',
      label: intl({ id: 'contact.interests.title' }),
    },
    {
      value: 'digital-tokenization',
      label: intl({ id: 'contact.blockchainInterests.tokenization' }),
      project: true,
    },
    {
      value: 'smart-contracts',
      label: intl({ id: 'contact.blockchainInterests.smartContracts' }),
      project: true,
    },
    {
      value: 'defi',
      label: intl({ id: 'contact.blockchainInterests.defi' }),
      project: true,
    },
    {
      value: 'blockchain-consulting',
      label: intl({ id: 'contact.blockchainInterests.consulting' }),
      project: true,
    },
    {
      value: 'blockchain-other',
      label: intl({ id: 'contact.blockchainInterests.other' }),
      project: false,
    },
  ]

  const whatsClientsSayingTestimonialsImages = {
    robImage: data.section7IconRobDurscki.childImageSharp,
    karenImage: data.section7IconKarenChang.childImageSharp,
  }

  return (
    <NavbarOnly>
      <SEO
        title={intl({ id: 'blockchainServices.seo.title' })}
        description={intl({ id: 'blockchainServices.seo.description' })}
      />

      <div>
        <BlockchainHero image={data.section1Image.childImageSharp} />
        <BlockchainDevelopementServices />
        <LetsChatAbout image={data.section4Image.childImageSharp} />
        <HelpedCompanies />
        <StellarIntegration />
        <WhatsClientsSaying testimonialsImages={whatsClientsSayingTestimonialsImages} />
        <BlockchainServices icons={data.section8Icons.edges} />
        <BlockchainDevelopmentProcess />
        <LearnMoreAbout images={data.section10Images.edges} />
        <TopNotchClutch image={data.section11Image.childImageSharp} />
        <FaqSection />

        <div id="lets-chat">
          <LestChatSection
            hubspotFormId={BLOCKCHAIN_CONTACT_FORM_ID}
            interestedSelectOptions={blockchainInterests}
            interestFieldName="interested_blockchain"
          />
        </div>
        <BlockchainFooter />
      </div>
    </NavbarOnly>
  )
}

export const query = graphql`
  query BlockChainPage {
    section1Image: file(relativePath: { eq: "blockchainServicePage/section1/image.png" }) {
      childImageSharp {
        fluid(maxWidth: 552) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    section2Image: file(relativePath: { eq: "blockchainServicePage/section2/image.png" }) {
      childImageSharp {
        fluid(maxWidth: 552) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    section4Image: file(relativePath: { eq: "blockchainServicePage/section4/image.png" }) {
      childImageSharp {
        fluid(maxWidth: 552) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    section7IconKarenChang: file(
      relativePath: { eq: "blockchainServicePage/section7/icon-karen-chang.png" }
    ) {
      childImageSharp {
        fixed {
          src
          srcSet
        }
      }
    }
    section7IconRobDurscki: file(
      relativePath: { eq: "blockchainServicePage/section7/icon-rob-durscki.png" }
    ) {
      childImageSharp {
        fixed {
          src
          srcSet
        }
      }
    }
    section8Icons: allFile(
      filter: { relativeDirectory: { eq: "blockchainServicePage/section8" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    section10Images: allFile(
      filter: { relativeDirectory: { eq: "blockchainServicePage/section10" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    section11Image: file(relativePath: { eq: "blockchainServicePage/section11/clutch-badge.png" }) {
      childImageSharp {
        fluid(maxWidth: 552) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

BlockChainPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}
export default BlockChainPage
